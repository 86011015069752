.image_container {
  max-width: 100%;
  top: -270px;
  opacity: 0.8;
  background-color: #ffffff;
  border-bottom: 1px solid #000000;
}

.race_resume_image {
  max-width: 100%;
}

.title_name {
  position: absolute;
  top: 40px;
  padding: 0 20px 0 50px;
  color: #ffffff;
  font-family: 'Itim', cursive;
  font-size: 60px;
  line-height: 60px;
}

.title_resume {
  position: absolute;
  top: 130px;
  padding: 0 20px 0 50px;
  font-family: 'Grechen Fuemen', cursive;
  color: #ffffff;
  font-size: 56px;
  line-height: 25px;
}

.resume_details_container {
  text-align: center;
}

.results_group {
  font-family: 'Itim', cursive;
  margin-top: 40px;
}

.year_container {
  margin-bottom: 30px;
}

.year_container2 {
  margin-bottom: 30px;
}

/* responsive handling */
@media (max-width: 1300px) {
  .title_name {
    position: absolute;
    top: 30px;
    padding: 0 20px 0 50px;
    color: #ffffff;
    font-family: 'Itim', cursive;
    font-size: 45px;
    line-height: 30px;
  }

  .title_resume {
        position: absolute;
        top: 100px;
        padding: 0 20px 0 50px;
        font-family: 'Grechen Fuemen', cursive;
        color: #ffffff;
        font-size: 48px;
        line-height: 20px;
    }
}

@media (max-width: 1000px) {
  .title_name {
    position: absolute;
    top: 20px;
    padding: 0 20px 0 20px;
    color: #ffffff;
    font-family: 'Itim', cursive;
    font-size: 36px;
    line-height: 30px;
  }

  .title_resume {
        position: absolute;
        top: 75px;
        padding: 0 20px 0 20px;
        font-family: 'Grechen Fuemen', cursive;
        color: #ffffff;
        font-size: 42px;
        line-height: 18px;
    }
}

@media (max-width: 800px) {
  .title_name {
    position: absolute;
    top: 10px;
    padding: 0 20px 0 10px;
    color: #ffffff;
    font-family: 'Itim', cursive;
    font-size: 28px;
    max-width: 50px;
    line-height: 30px;
  }

  .title_resume {
        position: absolute;
        top: 100px;
        right: 24px;
        font-family: 'Grechen Fuemen', cursive;
        color: #ffffff;
        font-size: 40px;
        line-height: 18px;
    }
}

@media (max-width: 523px) {
  .title_name {
    position: absolute;
    top: 10px;
    padding: 0 20px 0 10px;
    color: #ffffff;
    font-family: 'Itim', cursive;
    font-size: 20px;
    max-width: 50px;
    line-height: 18px;
  }

  .title_resume {
        position: absolute;
        top: 70px;
        right: 10px;
        font-family: 'Grechen Fuemen', cursive;
        color: #ffffff;
        font-size: 36px;
        line-height: 16px;
    }
}
